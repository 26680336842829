<template>
  <v-card-text>
    <v-row>
      <v-col
          sm="6"
          cols="12"
      >
        <v-autocomplete
            dense
            v-model="merchant_product_model.category"
            label="Category"
            :items="cat_prod"
            item-text="name"
            item-value="code"
            clearable
            :rules="[v => !!v || 'Category must filled']"
            return-object
        ></v-autocomplete>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-autocomplete
          dense
          v-model="merchant_product_model.merchant"
          label="Merchant"
          :items="merchant_prod"
          item-text="name"
          item-value="code"
          clearable
          :rules="[v => !!v || 'Merchant Product must filled']"
          return-object
          :disabled="!isAdmin"
        ></v-autocomplete>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="merchant_product_model.up_selling_price"
            sm="12"
            cols="6"
            label="Up Selling Price"
            type="number"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <template v-if="update === '1'">
        <v-col
            sm="6"
            cols="12"
        >
          <v-autocomplete
            dense
            v-model="merchant_product_model.active"
            label="Status"
            :items="status_list"
            clearable
            :rules="[v => !!v || 'Status must filled']"
          ></v-autocomplete>
        </v-col>
      </template>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "MerchantProductFormComponent",
  data: () => ({
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
    disabled: true,
  }),
  props: {
    merchant_product_model: Object,
    cat_prod: [],
    merchant_prod: [],
    status_list: [],
    isAdmin: Boolean,
    update: String,
  },
  mounted() {
    console.log('merchant product component')
  }
}
</script>

<style scoped>

</style>
