export function MerchantProductData() {
  return {
    merchant: {},
    category: {},
    up_selling_price: 0,
    active: 1,
  }
}

export function StatusList() {
  return [
    {text: 'Not Active', value: 0},
    {text: 'Active', value: 1},
    {text: 'Trouble', value: 2},
  ];
}
